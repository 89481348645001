export const colors = {
  main1: "#E6F0F7",
  main2: "#DAE9F3",
  main3: "#B2D1E6",
  main4: "#086CAE",
  main5: "#07619D",
  main6: "#06568B",
  primaryNormal: "#0066FF",
  primaryStrong: "#0055FF",
  primaryHeavy: "#0044CC",
  labelNormal: "#333333",
  labelStrong: "#000000",
  labelNeutral: "#4F4F4F",
  labelAlternative: "#7F7F7F",
  labelAssistive: "#BDBDBD",
  labelDisable: "#E0E0E0",
  backgroundNormal: "#FFFFFF", // #F5F5F5
  backgroundAlternative: "#F5F5F5", // #E8E8E8
  interactionInactive: "#A0A0A0",
  interactionDisable: "#E0E0E0",
  lineNormal: "#D0D0D0",
  lineNeutral: "#E0E0E0",
  lineAlternative: "#F0F0F0",
  statusPositive: "#00C853",
  statusCautionary: "#FFA000",
  statusDestructive: "#FF5252",
  accentRedOrange: "#FF5722",
  accentLime: "#C6FF00",
  accentCyan: "#00E5FF",
  accentLightBlue: "#40C4FF",
  accentViolet: "#7C4DFF",
  accentPurple: "#AB47BC",
  accentPink: "#FF4081",
  inversePrimary: "#4285F4",
  inverseBackground: "#212121",
  inverseLabel: "#212121",
  staticWhite: "#FFFFFF",
  staticBlack: "#000000",
  componentNormal: "#E0E0E0",
  componentStrong: "#D3D3D3",
  componentAlternative: "#F5F5F5",
  componentDimmer: "#757575"
};

export function applyColors() {
  Object.entries(colors).forEach(([key, value]) => {
    document.documentElement.style.setProperty(`--color-${key}`, value);
  });
}