import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { signInWithGoogle, logOut } from "../services/firebase/auth";
import { apiSignUp, apiLogOut, apiCheckToken } from "../services/firebase/database";
import axios from 'axios';
import { applyColors } from '../styles/applyColors';
import '../styles/Header.css';

applyColors();

function Header({ user, setUser }) {
  const navigate = useNavigate();
  const [token, setToken] = useState(0);
  const [showMenu, setShowMenu] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    const fetchToken = async () => {
      if (user) {
        try {
          const userToken = await apiCheckToken(user.uid);
          setToken(userToken);
        } catch (error) {
          console.error("Failed to fetch token:", error);
        }
      }
    };

    fetchToken();

    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setShowMenu(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [user]);

  const handleLogin = async () => {
    try {
      const newUser = await signInWithGoogle();
      if (newUser) {
        apiSignUp(newUser).then(respon => {
          if (respon === "SignUp"){
            axios.get(`https://shdobsuit0kt.share.zrok.io/send_signup_email?uid=${newUser.uid}`)
            .catch(error => {
              console.error('Error fetching data:', error);
            });
          }
        });
      }
    } catch (error) {
      console.error("Login failed:", error);
    }
  };

  const handleLogout = async () => {
    try {
      if (user) {
        await apiLogOut(user);
      }
      await logOut();
      navigate('/');
      setShowMenu(false);
    } catch (error) {
      console.error("Logout failed:", error);
    }
  };

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const handleTokenClick = () => {
    navigate('/addtoken');
  };

  const handleTestLogin = () => {
    const password = prompt("Enter password:");
    if (password === "test123") {
      setUser({
        email: "eggsy@aigenda.me",
        photoURL: "https://lh3.googleusercontent.com/a/ACg8ocKdldy2BgwJ3GLp6t58uBtKaQmww5ld-dMCz7utDqh_MdW9kQ=s96-c",
        displayName: "Info AiGENDA",
        uid: "To2yDEL97CdhMpZUaF5EQJVhFGL2"
      });
    } else {
      alert("Incorrect password!");
    }
  };

  return (
    <header className="header">
      <div className="header-content">
        <a href="/" className="home-link">
          <img 
            src="/logo.png" 
            alt="Logo" 
            className="logo" 
            style={{ cursor: 'pointer' }}
          />
        </a>
        <div className="user-section">
          {user ? (
            <>
              <div className="token-count" onClick={handleTokenClick} style={{ cursor: 'pointer' }}>
                보유한 토큰: {token}
              </div>
              <div className="user-menu-container" ref={menuRef}>
                <img 
                  src={user.photoURL} 
                  alt="User" 
                  className="user-photo"
                  onClick={toggleMenu}
                />
                {showMenu && (
                  <div className="user-menu">
                    <Link to="/user" className="menu-item">사용자 정보</Link>
                    <Link to="/addtoken" className="menu-item">결제하기</Link>
                    <button onClick={handleLogout} className="menu-item">로그아웃</button>
                  </div>
                )}
              </div>
            </>
          ) : (
            <>
              <button onClick={handleTestLogin} className="test-button">테스트</button>
              <button onClick={handleLogin} className="login-button">로그인</button>
            </>
          )}
        </div>
      </div>
    </header>
  );
}

export default Header;