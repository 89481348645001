import React from 'react';

import { applyColors } from '../styles/applyColors';
import '../styles/Footer.css';
applyColors()

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-company">
          <strong>(주)에이젠다</strong>
        </div>
        <div className="footer-info">
          <p>사업자 등록번호 : 160-25-01696 | 대표 : 안효인</p>
          <p>(16463) 경기도 수원시 행궁로 98, 수원시지속가능도시재단 창업지원센터 성장관 303호</p>
        </div>
        <div className="footer-links">
          <a href='/terms'>서비스 이용약관</a>
          <a href='/privacy-policy'>개인정보 처리방침</a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;